import './TradeInfoMediaSection.scss'
import { deleteMediaForNote, useMediaForNote } from 'core/api/trades'
import { useContext, useRef } from 'react'
import { TradeInfoModalContext } from '../../TradeInfoModalContext'
import { MediaItem } from 'core/types'
import { Modal, ModalController, useModal } from 'shared/Modal'
import { useTrackImgLoading } from '../../hooks'
import classNames from 'classnames'
import SkeletonBlock from 'shared/SkeletonBlock'
import Icon from 'shared/Icon'
import { Button } from 'shared/Button'
import { useCallAndReport } from 'core/hooks/useDoAndReport'
import { ModalLayout } from 'shared/ModalLayout'
import { TradesTableTabContext } from 'pages/HomePage/tabs/TradesTableTab/TradesTableTabContext'
import { useDropzone } from 'react-dropzone'
import { useAttachmentUploading } from './hooks'

export function TradeInfoMediaSection() {
  const { tradesResponse } = useContext(TradesTableTabContext)
  const { tradeItem } = useContext(TradeInfoModalContext)
  const mediaResponse = useMediaForNote(tradeItem.note?.id)

  const { uploadingList, handleFiles } = useAttachmentUploading({
    onMediaUpdate: mediaResponse.mutate,
    onTradeUpdate: tradesResponse.mutate,
  })

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDrop: handleFiles,
    noClick: true,
  })

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    console.log(event)
    event.preventDefault()

    const items = Array.from(event.clipboardData.items)
    const files: File[] = items
      .map((item) => item.getAsFile())
      .filter((file): file is File => !!file)

    handleFiles(files)
  }

  return (
    <div className="TradeInfoMediaSection" onPaste={handlePaste}>
      <div className="heading text-normal-bold">Attachments</div>
      <div className="attachments-section__wrapper">
        <div
          className={classNames('attachments-section', isDragActive ? 'dragged-over' : '')}
          {...getRootProps()}
        >
          <div className="buttons">
            Drop file here or{' '}
            <Button
              appearance="link"
              color="primary"
              size="small"
              onClick={() => inputRef.current?.click()}
            >
              browse
            </Button>
          </div>
          {uploadingList.length > 0 && (
            <div className="loaders">
              {uploadingList.map((uploadingItem) => (
                <AttachmentLoader
                  key={uploadingItem.file?.name ?? 'N/A'}
                  fileName={uploadingItem.file?.name ?? 'N/A'}
                  progress={uploadingItem.progress}
                />
              ))}
            </div>
          )}
          <div className="attachments">
            {mediaResponse.data?.result.map((mediaItem) => (
              <Attachment
                key={mediaItem.key}
                mediaItem={mediaItem}
                onUpdate={mediaResponse.mutate}
              />
            ))}
          </div>
          <input {...getInputProps()} />
          <div className="file-drop-overlay">Drop file here</div>
        </div>
      </div>
    </div>
  )
}

type AttachmentLoaderProps = { fileName: string; progress?: number | null }
export function AttachmentLoader({ fileName, progress }: AttachmentLoaderProps) {
  return (
    <div className="AttachmentLoader">
      <div className="text">
        <div className="file-name">{fileName}</div>
        <div className="file-status">
          Uploading... {progress != null && Math.round(progress) + '%'}
        </div>
      </div>
      <div className="progress">
        <div
          className="progress-bar"
          style={{ width: progress != null ? progress + '%' : 0 }}
        ></div>
      </div>
    </div>
  )
}

type ImageItemProps = { mediaItem: MediaItem; onUpdate: () => Promise<unknown> }
export function Attachment({ mediaItem, onUpdate }: ImageItemProps) {
  const previewModal = useModal()
  const deleteModal = useModal()
  const thumbnailImgRef = useRef<HTMLImageElement>(null)
  const isThumbnailLoading = useTrackImgLoading(thumbnailImgRef)
  const { tradeItem } = useContext(TradeInfoModalContext)

  const { callAndReport, isLoading } = useCallAndReport()

  const openDeleteModal = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.stopPropagation()
    deleteModal.open()
  }

  const deleteMedia = async () => {
    await callAndReport(
      async () => {
        if (!tradeItem.note) throw new Error('Cannot delete media without a trade note')
        await deleteMediaForNote(tradeItem.note.id, mediaItem.key)
        await onUpdate()
      },
      { DEFAULT_ERR: 'Failed to delete image', OK: 'Image removed' }
    )
  }

  return (
    <>
      <div
        className={classNames('Attachment', isThumbnailLoading && 'loading')}
        onClick={() => previewModal.open()}
      >
        <SkeletonBlock className="thumbnail-skeleton" />
        <Button square size="small" className="delete" onClick={(ev) => openDeleteModal(ev)}>
          <Icon name="Trash" />
        </Button>
        <div className="hover-card">
          <Icon name="ZoomIn" width="24px" height="24px" />
        </div>
        <img
          ref={thumbnailImgRef}
          src={mediaItem.thumbnailSignedUrl}
          alt="thumbnail"
          className="thumbnail"
        />
      </div>
      <Modal controller={deleteModal}>
        <ModalLayout>
          <ModalLayout.Heading>Delete attachment</ModalLayout.Heading>
          <ModalLayout.Description>
            Are you sure you want to delete this attachment?
          </ModalLayout.Description>
          <ModalLayout.Buttons>
            <Button onClick={() => deleteModal.close()}>Cancel</Button>
            <Button color="error" onClick={deleteMedia} isLoading={isLoading}>
              Delete
            </Button>
          </ModalLayout.Buttons>
        </ModalLayout>
      </Modal>
      <TradeInfoNoteImagePreviewModal controller={previewModal} mediaItem={mediaItem} />
    </>
  )
}

type TradeInfoNoteImagePreviewModalProps = { controller: ModalController; mediaItem: MediaItem }
function TradeInfoNoteImagePreviewModal({
  controller,
  mediaItem,
}: TradeInfoNoteImagePreviewModalProps) {
  return (
    <Modal controller={controller}>
      <div className="TradeNotePreviewImageItemModal">
        <img src={mediaItem.thumbnailSignedUrl} alt="thumbnail" className="thumbnail" />
        <img src={mediaItem.signedUrl} className="full" alt="Full" />
        <Button
          className="close-button"
          square
          size="large"
          appearance="menu-item"
          onClick={() => controller.close()}
        >
          <Icon name="Cross" width="24px" height="24px" />
        </Button>
      </div>
    </Modal>
  )
}
