import { useCallback, useContext, useMemo, useState } from 'react'
import { TradeInfoModalContext } from '../../TradeInfoModalContext'
import { useNotifications } from 'shared/Notifications'
import { addMediaToNote, createNote } from 'core/api/trades'
import { AxiosError } from 'axios'
import { Note } from 'core/types'

const FILE_PARAMS = {
  maxSize: 5 * 1024 * 1024,
  allowedFormats: ['image/jpeg', 'image/png'],
}

type UploadingItem = { file: File; startTimestamp: number; progress?: number | null }
type UseAttachmentUploadingConfig = {
  onMediaUpdate: () => Promise<unknown>
  onTradeUpdate: () => Promise<unknown>
}
export function useAttachmentUploading({
  onMediaUpdate,
  onTradeUpdate,
}: UseAttachmentUploadingConfig) {
  const { tradeItem } = useContext(TradeInfoModalContext)
  const [uploadingMap, setUploadingMap] = useState<Record<string, UploadingItem>>({})
  const uploadingList = useMemo(
    () => Object.values(uploadingMap).sort((a, b) => a.startTimestamp - b.startTimestamp),
    [uploadingMap]
  )
  const { showNotification } = useNotifications()

  const addFileToMap = useCallback(
    (file: File) => {
      if (uploadingMap[file.name]) throw new Error('File already exists in uploading map')
      setUploadingMap({
        ...uploadingMap,
        [file.name]: {
          file,
          startTimestamp: Date.now(),
          progress: undefined,
        } satisfies UploadingItem,
      })
    },
    [uploadingMap]
  )

  const updateProgress = useCallback((fileName: string, progress?: number | null) => {
    setUploadingMap((oldMap) => ({
      ...oldMap,
      [fileName]: { ...oldMap[fileName], progress } satisfies UploadingItem,
    }))
  }, [])

  const removeFileFromMap = useCallback(
    (fileName: string) => {
      const { [fileName]: _, ...rest } = uploadingMap
      setUploadingMap(rest)
    },
    [uploadingMap]
  )

  const uploadImage = useCallback(
    async (file: File) => {
      try {
        addFileToMap(file)
        let currentNote: Note
        if (tradeItem.note) {
          currentNote = tradeItem.note
        } else {
          const response = await createNote(tradeItem.id)
          currentNote = response.data.result
        }
        await addMediaToNote(currentNote.id, file, (ev) => {
          console.log('progress', ev.progress ? ev.progress * 100 : null)
          updateProgress(file.name, ev.progress ? ev.progress * 100 : null)
        })
        if (!tradeItem.note) await onTradeUpdate()
        await onMediaUpdate()
      } catch (err) {
        console.error(err)
        if (err instanceof AxiosError && err.response?.data) {
          showNotification({
            text: err.response.data.message ?? 'Failed to upload image',
            type: 'error',
          })
        } else {
          showNotification({
            text: 'Failed to upload image',
            type: 'error',
          })
        }
      } finally {
        removeFileFromMap(file.name)
      }
    },
    [
      addFileToMap,
      onMediaUpdate,
      onTradeUpdate,
      removeFileFromMap,
      showNotification,
      tradeItem.id,
      tradeItem.note,
      updateProgress,
    ]
  )

  const handleFiles = useCallback(
    (files: (File | null)[]) => {
      files.forEach((file) => {
        if (!file) return
        if (file.size > FILE_PARAMS.maxSize) {
          return showNotification({
            text: 'File size should be less than 5MB',
            type: 'error',
          })
        }
        if (!FILE_PARAMS.allowedFormats.includes(file.type)) {
          return showNotification({
            text: 'File should be an JPG or PNG image',
            type: 'error',
          })
        }
        uploadImage(file)
      })
    },
    [showNotification, uploadImage]
  )

  return { uploadingList, uploadImage, uploadingMap, handleFiles }
}
