import { TradeItem } from 'core/types'
import { Button } from 'shared/Button'
import Icon from 'shared/Icon'
import Menu from 'shared/Menu'
import TableCell from 'shared/Table/components/TableCell'
import { TradesTableTabContext } from '../../../TradesTableTabContext'
import { useContext } from 'react'

type MenuCellProps = { item: TradeItem }
export function MenuCell({ item }: Readonly<MenuCellProps>) {
  const { openMoveTradeToModal, openConfirmDeleteTradeModal } = useContext(TradesTableTabContext)

  return (
    <TableCell.Menu>
      <div className="center">
        <Menu
          trigger={
            <Button
              appearance="text"
              className="trade-menu-button"
              size="small"
              square
              disabled={item.isLocked}
            >
              <Icon name="DotsVertical" />
            </Button>
          }
        >
          {item.isLocked === false && (
            <>
              <Menu.Item onClick={() => openMoveTradeToModal(item)}>Move to...</Menu.Item>
              <Menu.Item className="red" onClick={() => openConfirmDeleteTradeModal(item)}>
                Delete trade
              </Menu.Item>
            </>
          )}
        </Menu>
      </div>
    </TableCell.Menu>
  )
}
