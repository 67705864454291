import { blankTradeItem, TradeItem } from 'core/types'
import { createContext, useContext, useMemo, useState } from 'react'
import { TradesTableTabContext } from '../../TradesTableTabContext'

export type TradeNoteChangeItem =
  | {
      type: 'DELETE_IMG'
      imgId: string
    }
  | {
      type: 'ADD_IMG'
      img: File
    }
  | {
      type: 'EDIT_NOTE'
      note: string
    }

export type TradeInfoModalContextData = {
  tradeItem: TradeItem
  changesPull: TradeNoteChangeItem[]
  setChangesPull: React.Dispatch<React.SetStateAction<TradeNoteChangeItem[]>>
  editMode: boolean
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>
  onClose: () => void
}
export const TradeInfoModalContext = createContext<TradeInfoModalContextData>({
  tradeItem: blankTradeItem,
  changesPull: [],
  setChangesPull: () => {},
  editMode: false,
  setEditMode: () => {},
  onClose: () => {},
})
type TradeInfoModalContextProviderProps = { children: React.ReactNode; onClose: () => void }
export function TradeInfoModalContextProvider({
  children,
  onClose,
}: TradeInfoModalContextProviderProps) {
  const { selectedTrade: tradeItem } = useContext(TradesTableTabContext)
  if (tradeItem === null) throw new Error('Trade item is not selected. This should not happen.')
  const [editMode, setEditMode] = useState(false)
  const [changesPull, setChangesPull] = useState<TradeNoteChangeItem[]>([])

  const value = useMemo(
    () =>
      ({
        tradeItem,
        editMode,
        setEditMode,
        onClose,
        changesPull,
        setChangesPull,
      } satisfies TradeInfoModalContextData),
    [editMode, onClose, tradeItem, changesPull, setChangesPull]
  )
  return <TradeInfoModalContext.Provider value={value}>{children}</TradeInfoModalContext.Provider>
}
