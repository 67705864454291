import { PagingParams, SortingParams, useTrades } from 'core/api/dashboard'
import { useFilters } from 'core/contexts/FilterContext'
import { useEffect } from 'react'
import { TradeItem } from 'core/types'
import { useSelectedJournal } from 'pages/HomePage/components/Filters/useSelectedJournal'
import { useUserInfo } from 'core/api/common'

export function useFilteredTrades(pagingSortingParams: PagingParams & SortingParams<TradeItem>) {
  const { filters } = useFilters()
  useEffect(() => console.log('pagingSortingParams', pagingSortingParams), [pagingSortingParams])
  return useTrades(filters, pagingSortingParams)
}

export function useSelectedJournalIsLocked() {
  const selectedJournal = useSelectedJournal()
  const userInfo = useUserInfo()
  if (!selectedJournal) return false
  if (selectedJournal.typeEditable) return false
  return selectedJournal.owner.userEmail !== userInfo.data?.userEmail
}
